<template>
  <div class="app-layout" v-if="datainited">
    <div class="topstepper">
      <van-steps :active="curstep">
        <van-step>{{ PAGELANG.经销商注册 }}</van-step>
        <van-step>{{ PAGELANG.实名认证 }}</van-step>
        <van-step>{{ PAGELANG.保证金交付 }}</van-step>
        <van-step>{{ PAGELANG.完成 }}</van-step>
      </van-steps>
    </div>
    <div class="mainarea">
      <div v-if="applystep == 5">
        <div class="logowraper">
          <div class="logo">
            <van-image
              class="logoimg"
              width="30vw"
              height="30vw"
              round
              fit="contain"
              lazy-load
              :src="require('@/assets/images/logo.png')"
            />
          </div>
        </div>
        <div class="infoboard">
          {{ PAGELANG.您的货款凭证审核中 }}
        </div>
        <div style="margin: 16px">
          <van-button
            color="var(--van-card-price-color)"
            class="btn-submit"
            block
            type="primary"
            @click="onRefresh"
            native-type="submit"
          >
            {{ PAGELANG.刷新页面 }}
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button
            block
            color="var(--van-card-price-color-light)"
            class="btn-submit"
            type="warning"
            @click="onLogout"
            native-type="submit"
          >
            {{ PAGELANG.退出登录 }}
          </van-button>
        </div>
      </div>
      <div v-if="applystep == 4 || applystep == 6" class="applystep4a6">
        <van-notice-bar
          v-if="applystep == 6"
          left-icon="volume-o"
          :text="RejectReason"
        />
        <div class="accountlist" v-for="accitem in accounts" :key="accitem">
          <h3 style="border-bottom: 1px solid #ddd">
            {{ PAGELANG.平台收款账户 }}
          </h3>
          <h3 style="margin-top: 5px">
            <label>{{ PAGELANG.收款人 }}</label
            >{{ accitem.AccountName }}
          </h3>
          <h4>
            <label>{{ PAGELANG.账户号 }}</label
            >{{ accitem.Account }}
          </h4>
          <h5>
            <label>{{ PAGELANG.开户行 }}</label
            >{{ accitem.BankName }}
          </h5>
        </div>
        <van-form @submit="onPaySubmit">
          <van-field
            v-model="Amount"
            name="Amount"
            :label="PAGELANG.货款金额"
            :placeholder="PAGELANG.请输入货款金额"
            :formatter="formatter"
            :rules="[
              { required: true, message: this.PAGELANG.货款金额不能为空 },
            ]"
            label-align="top"
            class="amount"
          />
          <van-field
            name="TradeNo"
            v-model="TradeNo"
            :readonly="isloadingdata"
            :label="PAGELANG.交易流水号"
            :placeholder="PAGELANG.请输入交易流水号"
            :rules="[
              { required: true, message: this.PAGELANG.交易流水号不能为空 },
            ]"
            label-align="top"
          />
          <div class="van-cell van-field">
            <div class="van-cell__title van-field__label van-field__label--top">
              {{ PAGELANG.交易流水截图 }}
            </div>
            <van-uploader
              v-model="TradeProof"
              multiple
              :readonly="isloadingdata"
              :deletable="!isloadingdata"
              :max-count="3"
              :upload-text="PAGELANG.上传截图"
              :before-read="beforeRead"
              :after-read="uploadTradeProof"
              class="trade-proof"
            />
            <div
              v-if="showTradeProofMsg"
              class="van-field__error-message"
              style="width: 100%"
            >
              {{ PAGELANG.请上传交易流水截图 }}
            </div>
          </div>
          <div style="margin: 16px">
            <van-button
              type="primary"
              block
              color="var(--van-card-price-color)"
              class="btn-submit"
              native-type="submit"
              :loading="isloadingdata"
              >{{ PAGELANG.立即充值 }}</van-button
            >
          </div>
        </van-form>
      </div>
      <div v-if="applystep == 2">
        <div class="logowraper">
          <div class="logo">
            <van-image
              class="logoimg"
              width="30vw"
              height="30vw"
              round
              fit="contain"
              lazy-load
              :src="require('@/assets/images/logo.png')"
            />
          </div>
        </div>
        <div class="infoboard">
          {{ PAGELANG.您的实名资料正在审核中 }}
        </div>
        <div style="margin: 16px">
          <van-button
            color="var(--van-card-price-color)"
            class="btn-submit"
            block
            type="primary"
            @click="onRefresh"
            native-type="submit"
          >
            {{ PAGELANG.刷新页面 }}
          </van-button>
        </div>
        <div style="margin: 16px">
          <van-button
            block
            color="var(--van-card-price-color-light)"
            class="btn-submit"
            type="warning"
            @click="onLogout"
            native-type="submit"
          >
            {{ PAGELANG.退出登录 }}
          </van-button>
        </div>
      </div>
      <div class="content" v-if="applystep == 1 || applystep == 3">
        <van-notice-bar
          v-if="applystep == 3"
          left-icon="volume-o"
          :text="RejectReason"
        />
        <van-form class="login-form">
          <van-field
            v-model="Contact"
            :label="PAGELANG.姓名"
            :placeholder="PAGELANG.真实姓名"
          />
          <van-field
            v-model="Address"
            :label="PAGELANG.地址"
            :placeholder="PAGELANG.住址或办公地址"
          />
          <van-field
            v-model="IDNumber"
            :label="PAGELANG.证件号"
            :placeholder="PAGELANG.身份证号码"
          />
          <van-field name="uploaderface" :label="PAGELANG.证件正面">
            <template #input>
              <van-uploader
                v-model="idcardface"
                :before-delete="
                  (file) => {
                    return this.imgdelete('idcardface', file);
                  }
                "
                :after-read="
                  (file) => {
                    this.imgreaded('idcardface', file);
                  }
                "
                max-count="1"
                image-fit="contain"
              />
            </template>
          </van-field>
          <!--<van-field name="uploaderback" :label="PAGELANG.证件反面">
            <template #input>
              <van-uploader
                v-model="idcardback"
                :before-delete="
                  (file) => {
                    return this.imgdelete('idcardback', file);
                  }
                "
                :after-read="
                  (file) => {
                    this.imgreaded('idcardback', file);
                  }
                "
                max-count="1"
                image-fit="contain"
              />
            </template>
          </van-field>-->
          <div style="margin: 16px">
            <van-button
              block
              color="var(--van-card-price-color)"
              class="btn-submit"
              type="primary"
              @click="onSubmit"
              :disabled="isloadingdata"
              native-type="submit"
            >
              {{ PAGELANG.提交 }}
            </van-button>
          </div>
          <div style="margin: 16px">
            <van-button
              block
              color="var(--van-card-price-color-light)"
              class="btn-submit"
              type="warning"
              @click="onLogout"
              native-type="submit"
            >
              {{ PAGELANG.退出登录 }}
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>

  <van-notice-bar
    class="pc-info"
    v-if="applystep == 3 || applystep == 6"
    left-icon="volume-o"
    :text="RejectReason"
  />
  <div class="app-layout-pc" v-if="datainited">
    <el-steps :active="curstep + 1" align-center>
      <el-step :title="PAGELANG.经销商注册" />
      <el-step :title="PAGELANG.实名认证" />
      <el-step :title="PAGELANG.保证金交付" />
      <el-step :title="PAGELANG.完成" />
    </el-steps>
    <div v-if="applystep == 1 || applystep == 3" class="content">
      <el-form label-width="100px" class="form-appflow" status-icon>
        <el-form-item :label="PAGELANG.姓名">
          <el-input
            v-model="Contact"
            :placeholder="PAGELANG.真实姓名"
            style="width: 20vw"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.地址">
          <el-input
            v-model="Address"
            :placeholder="PAGELANG.住址或办公地址"
            style="width: 30vw"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.证件号">
          <el-input
            v-model="IDNumber"
            :placeholder="PAGELANG.身份证号码"
            style="width: 30vw"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.证件正面">
          <van-uploader
            class="uploader"
            v-model="idcardface"
            :before-delete="
              (file) => {
                return this.imgdelete('idcardface', file);
              }
            "
            :after-read="
              (file) => {
                this.imgreaded('idcardface', file);
              }
            "
            :preview-full-image="false"
            max-count="1"
            image-fit="contain"
            preview-size="10vw"
          />
        </el-form-item>
        <el-form-item>
          <van-button
            color="var(--van-card-price-color)"
            class="btn-submit"
            type="primary"
            @click="onSubmit"
            :disabled="isloadingdata"
            native-type="submit"
            style="width: 10vw; margin-right: 2vw"
          >
            {{ PAGELANG.提交 }}
          </van-button>
          <van-button
            color="var(--van-card-price-color-light)"
            class="btn-submit"
            type="warning"
            @click="onLogout"
            native-type="submit"
            style="width: 10vw"
          >
            {{ PAGELANG.退出登录 }}
          </van-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="applystep == 4 || applystep == 6" class="content">
      <el-form label-width="120px" class="form-appflow" status-icon>
        <el-form-item :label="PAGELANG.平台收款账户">
          <div v-for="accitem in accounts" :key="accitem" class="account">
            <div class="c-01">
              <label>{{ PAGELANG.收款人 }}</label
              >{{ accitem.AccountName }}
            </div>
            <div class="c-01">
              <label>{{ PAGELANG.账户号 }}</label
              >{{ accitem.Account }}
            </div>
            <div class="c-02">
              <label>{{ PAGELANG.开户行 }}</label
              >{{ accitem.BankName }}
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="PAGELANG.货款金额"
          :rules="[{ required: true, message: this.PAGELANG.货款金额不能为空 }]"
        >
          <el-input
            name="Amount"
            v-model="Amount"
            :placeholder="PAGELANG.请输入货款金额"
            :formatter="formatter"
            style="width: 20vw"
          />
        </el-form-item>
        <el-form-item
          :label="PAGELANG.交易流水号"
          :rules="[
            { required: true, message: this.PAGELANG.交易流水号不能为空 },
          ]"
        >
          <el-input
            name="TradeNo"
            v-model="TradeNo"
            :readonly="isloadingdata"
            :placeholder="PAGELANG.请输入交易流水号"
            style="width: 20vw"
          />
        </el-form-item>
        <el-form-item :label="PAGELANG.交易流水截图" :required="true">
          <van-uploader
            v-model="TradeProof"
            multiple
            :readonly="isloadingdata"
            :deletable="!isloadingdata"
            :max-count="3"
            :upload-text="PAGELANG.上传截图"
            :before-read="beforeRead"
            :after-read="uploadTradeProof"
            preview-size="10vw"
            image-fit="contain"
            class="uploader"
          />
        </el-form-item>
        <el-form-item>
          <van-button
            type="primary"
            color="var(--van-card-price-color)"
            class="btn-submit"
            :loading="isloadingdata"
            style="width: 10vw"
            @click="onPaySubmitPC"
            >{{ PAGELANG.立即充值 }}</van-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div v-if="applystep == 5" class="content shz">
      <div class="logo">
        <van-image
          class="logoimg"
          width="10vw"
          height="10vw"
          round
          fit="contain"
          lazy-load
          :src="require('@/assets/images/logo.png')"
        />
      </div>
      <div class="infoboard">
        {{ PAGELANG.您的货款凭证审核中 }}
      </div>
      <div class="btns">
        <van-button
          color="var(--van-card-price-color)"
          class="btn-submit"
          block
          type="primary"
          @click="onRefresh"
          native-type="submit"
          style="width: 10vw; margin-right: 2vw"
        >
          {{ PAGELANG.刷新页面 }}
        </van-button>
        <van-button
          block
          color="var(--van-card-price-color-light)"
          class="btn-submit"
          type="warning"
          @click="onLogout"
          native-type="submit"
          style="width: 10vw"
        >
          {{ PAGELANG.退出登录 }}
        </van-button>
      </div>
    </div>
  </div>
</template>
  
<style scoped>
@media (max-width: 768px) {
  .app-layout-pc,
  .pc-info {
    display: none;
  }
  .accountlist {
    border: 1px solid #ccc;
    margin: 12px;
    min-height: 60px;
    border-radius: 6px;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1), 0 0px 2px rgba(0, 0, 0, 0.08);
    background: #fff;
    padding: 12px;
  }

  .accountlist h3,
  .accountlist h4,
  .accountlist h5 {
    margin: 0;
    padding: 0;
    line-height: 28px;
  }

  .accountlist h3 {
    font-size: 14px;
    color: #666;
  }

  .accountlist h4,
  .accountlist h5 {
    font-size: 14px;
    color: #666;
  }

  .accountlist h3 label,
  .accountlist h4 label,
  .accountlist h5 label {
    display: inline-block;
    padding-right: 5px;
  }
  .loadingwraper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .loadingwraper .block {
    width: 60px;
    height: 60px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .credit-block {
    padding: 8vw 16px;
    margin-top: -15vw;
    position: relative;
    z-index: 10;
    background-color: #fff;
    border-radius: 16px;
    min-height: 300px;

    --van-cell-vertical-padding: 13px;
    --van-padding-base: 6px;
    --van-cell-font-size: 16px;
    --van-field-label-color: #000;
  }

  .credit-block .amount >>> .van-cell__value .van-field__body::before {
    content: var(--aog-currency);
    color: #000;
    margin-right: 4px;
  }

  .credit-block .btn-submit {
    margin-top: 12vw;
    --van-button-normal-font-size: var(--van-submit-bar-font-size);
    --van-button-default-height: var(--van-submit-bar-button-height);
  }

  .credit-block .trade-proof >>> .van-uploader__upload-text {
    margin-top: 0;
  }

  .app-layout {
    --van-step-active-color: #cfaf6d;
    --van-step-finish-line-color: #cfaf6d;
  }

  .mainarea .logowraper {
    text-align: center;
    padding: 40px 0;
  }

  .mainarea .logo {
    position: relative;
    border-radius: 50%;
    display: inline-block;
    margin: auto;
  }

  .mainarea .logo::before,
  .mainarea .logo::after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: inherit;
  }

  .mainarea .logo::before {
    top: 1px;
    left: 1px;
    z-index: 10;
    background-color: #fff;
  }

  .mainarea .logo::after {
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1px;
    background: linear-gradient(to right, #fff, #d5ba80);
  }

  .mainarea .logo .logoimg {
    z-index: 100;
  }

  .mainarea .infoboard {
    line-height: 32px;
    text-align: center;
    color: #cfaf6d;
  }

  .app-layout .topstepper {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    box-shadow: 0 6px 4px rgba(0, 0, 0, 0.1), 0 3px 2px rgba(0, 0, 0, 0.08);
    --van-cell-horizontal-padding: 0;
    --van-padding-md: 0;
    height: 74px;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
  }

  .app-layout .mainarea {
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
    padding-top: 86px;
  }

  .app-layout .mainarea .applystep4a6 {
    height: 100%;
    overflow: auto;
  }

  .app-layout .mainarea .content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .van-icon-applicon {
    color: #cfaf6d;
  }
}

@media (min-width: 769px) {
  .app-layout {
    display: none;
  }
  .app-layout-pc {
    padding: 30px 20%;
  }
  .app-layout-pc {
    --el-color-primary: #cfaf6d;
  }
  .app-layout-pc .content {
    padding: 40px 8vw;
    --van-uploader-icon-size: 4vw;
  }
  .app-layout-pc .content .uploader >>> .van-image {
    background-color: #f7f8fa;
  }
  .app-layout-pc .content .account {
    display: flex;
    flex-wrap: wrap;
    width: 30vw;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dotted #ddd;
  }
  .app-layout-pc .content .account .c-01 {
    width: 100%;
  }
  .app-layout-pc .content.shz {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app-layout-pc .content.shz .infoboard {
    font-size: 1vw;
    color: var(--el-color-primary);
  }
  .app-layout-pc .content.shz .btns {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    padding-top: 4vw;
  }
}
</style>

<script>
import { resizeImage, setCookie, doResizeImage } from "../util/index.js";
import { closeToast, showNotify, showLoadingToast } from "vant";
export default {
  data() {
    return {
      PAGELANG: {},
      islogin: false,
      curstep: 0,
      applystep: 1,
      Contact: "",
      IDNumber: "",
      Address: "",
      idcardface: [],
      idcardback: [],
      idcardimgs: {
        idcardface: "",
        idcardback: "",
      },
      datainited: false, //
      Amount: "",
      TradeNo: "",
      TradeProof: [],
      showTradeProofMsg: false,
      isloadingdata: false,
      accounts: [],
      Deposit: 0,
      realname: {},
    };
  },
  mounted() {
    //加载登录数据
    // checkLogin(this).then((res) => {
    //     if(res == 'OK') {
    //         //进入系统
    //         this.datainited = true;
    //     } else {
    //         //跳到登录
    //         this.$router.push('/login');
    //     }
    // });

    console.log(this.actions.getagentinfo);

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios.get(this.actions.getagentinfo).then((response) => {
      //经销商信息
      closeToast();
      console.log(response.data);
      if (!response.data || response.data.retcode == "CHKLOINFAILED") {
        this.$router.push("/login");
        return;
      }

      let agentinfo = response.data.agentinfo;

      if (!agentinfo) {
        //查不到经销商信息
        this.$router.push("/login");
        return;
      }

      //如果已经审核通过，就不能进入该界面
      console.log(agentinfo);
      if (parseInt(agentinfo["Status"]) == 1) {
        this.$router.push("/");
        return;
      }

      console.log(agentinfo);

      this.applystep = parseInt(response.data.ApplStep);

      switch (this.applystep) {
        case 1: //填写实名资料
        case 2: //实名审核中
        case 3: //实名拒绝，拒绝原因读Reason
          this.curstep = 1;
          if (this.applystep == 3) {
            this.RejectReason = agentinfo["Reason"];
          }
          break;
        case 4: //货款凭证填写
        case 5: //货款凭证审核中
        case 6: //货款凭证拒绝，拒绝原因读Reason
          this.curstep = 2;
          this.Amount = response.data.Deposit;
          this.Deposit = response.data.Deposit;
          if (this.applystep == 6) {
            this.RejectReason = response.data.RejectReason;
          }
          break;
        case 7:
          this.curstep = 3;
          break;
      }

      console.log(this.curstep, this.applystep);

      this.Contact = agentinfo["Contact"];
      this.IDNumber = agentinfo["IDNumber"];
      this.Address = agentinfo["Address"];

      let idimgs = false;

      if (agentinfo["LegalPersonIDS"])
        idimgs = JSON.parse(agentinfo["LegalPersonIDS"]);

      if (idimgs) {
        this.idcardface = [{ url: this.uploadbase + idimgs.idcardface }];
        this.idcardback = [{ url: this.uploadbase + idimgs.idcardback }];

        // this.idcardimgs.idcardface = idimgs.idcardface;
        // this.idcardimgs.idcardback = idimgs.idcardback;
      }

      //全部通过
      this.datainited = true;
    });

    this.axios.get(this.actions.getpfaccount).then(({ data }) => {
      if (data.retcode == "OK") {
        //显示数据
        this.accounts = data.accounts;
      }
    });
  },
  beforeCreate() {
    //console.log(this.LANG.pages.appflow);
  },
  methods: {
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }

      return thousands.split("").reverse().join("");
    },
    formatter(val) {
      return val.replace(/[^\d]*/g, "").replace(/(?=(\B)(\d{3})+$)/g, ".");
    },
    beforeRead(file) {
      console.log("beforeRead", file);
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        //showToast(this.PAGELANG.uploadfile);
        showNotify({
          message: this.PAGELANG.uploadfile,
          type: "warning",
        });
        return false;
      }
      return true;
    },
    uploadTradeProof(file) {
      console.log(file);
    },
    onPaySubmitPC() {
      if (this.Amount.length <= 0) {
        this.isloadingdata = true;
        showNotify({
          message: this.PAGELANG.请输入货款金额,
          type: "warning",
          onClose: () => {
            this.isloadingdata = false;
            closeToast();
          },
        });
        return false;
      }

      if (this.TradeNo.trim().length <= 0) {
        this.isloadingdata = true;
        showNotify({
          message: this.PAGELANG.交易流水号不能为空,
          type: "warning",
          onClose: () => {
            this.isloadingdata = false;
            closeToast();
          },
        });
        return false;
      } else {
        this.onPaySubmit();
      }
    },
    onPaySubmit() {
      let Amount = this.Amount.replaceAll(".", "");
      if (this.isloadingdata) return false;

      if (Amount * 1 != this.Deposit * 1) {
        showNotify({
          message:
            this.PAGELANG.货款金额必须为 + this.formatMoney(this.Deposit),
          type: "warning",
          onClose: () => {
            this.isloadingdata = false;
            closeToast();
          },
        });
        return false;
      }

      this.showTradeProofMsg = false;
      this.isloadingdata = true;
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      let TradeProof = [];

      if (this.TradeProof.length <= 0) {
        showNotify({
          message: this.PAGELANG.请传至少一张凭证,
          type: "warning",
          onClose: () => {
            this.isloadingdata = false;
            closeToast();
          },
        });
      }
      for (let i = 0; i < this.TradeProof.length; i++) {
        doResizeImage(this.TradeProof[i].content, 1000, 1000).then((res) => {
          this.TradeProof[i].content = res;
          TradeProof[i] = res;
          if (i == this.TradeProof.length - 1) {
            if (this.TradeProof.length <= 0) {
              this.showTradeProofMsg = true;
              return false;
            }

            let postdata = new Object();
            postdata.Amount = Amount;
            postdata.TradeNo = this.TradeNo;
            postdata.TradeProof = TradeProof;

            //console.log(postdata);

            this.axios
              .post(this.actions.initcharge, JSON.stringify(postdata))
              .then(({ data }) => {
                console.log(data);
                let { retcode, retinfo, newapplystep } = data;
                let showinfo = this.PAGELANG[retinfo];
                if (!showinfo) showinfo = retinfo;

                console.log(retcode, showinfo);
                let msgtype = "warning";
                if (retcode == "OK") msgtype = "success";
                showNotify({
                  message: showinfo,
                  type: msgtype,
                  onClose: () => {
                    if (retcode == "OK") {
                      this.applystep = newapplystep;
                    }
                    this.isloadingdata = false;
                    closeToast();
                    this.onRefresh();
                  },
                });
              });
          }
        });
      }
    },
    imgdelete(name, file) {
      //删除一个文件
      console.log(name, file);
      return true;
    },
    imgreaded(name, file) {
      //console.log(name, file.content, this.idcardface, this.idcardback);

      console.log(name, file, this.idcardface);

      resizeImage(file.content).then((dturl) => {
        //改尺寸后
        //console.log(file.content, dturl);
        this.idcardimgs[name] = dturl;

        //console.log(this.idcardimgs);
      });
    },
    onRefresh() {
      //this.$router.go(0);
      location.reload();
    },
    onSubmit() {
      if (this.isloadingdata) return false;

      this.isloadingdata = true; //正在提交

      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      //提交资料
      let postobj = new Object();

      postobj.Contact = this.Contact;
      postobj.IDNumber = this.IDNumber;
      postobj.Address = this.Address;
      postobj.idcardimgs = this.idcardimgs;

      let checkerror = false;
      if (!postobj.Contact || postobj.Contact.length <= 0) {
        //真实姓名不能为空
        checkerror = "真实姓名不能为空";
      } else if (!postobj.Address || postobj.Address.length <= 0) {
        //请填写地址
        checkerror = "请填写地址";
      } else if (!postobj.IDNumber || postobj.IDNumber.length <= 0) {
        //请填写证件号
        checkerror = "请填写证件号";
      } else if (
        (!this.idcardface || this.idcardface.length <= 0) &&
        (!postobj.idcardimgs ||
          !postobj.idcardimgs.idcardface ||
          postobj.idcardimgs.idcardface.length <= 0)
      ) {
        //请上传证件照正面
        console.log(this.idcardimgs);
        checkerror = "请上传证件照正面";
      } else if (
        (!this.idcardback || this.idcardback.length <= 0) &&
        (!postobj.idcardimgs ||
          !postobj.idcardimgs.idcardback ||
          postobj.idcardimgs.idcardback.length <= 0)
      ) {
        //请上传证件照反面
        //checkerror = "请上传证件照反面";
      }

      console.log(this.idcardface, postobj.idcardimgs.idcardface.length);

      if (this.PAGELANG[checkerror]) checkerror = this.PAGELANG[checkerror];

      //console.log(checkerror);

      if (checkerror) {
        //检验不通过
        // showFailToast({
        //   message: checkerror,
        // });

        showNotify({
          message: checkerror,
          type: "warning",
        });

        this.isloadingdata = false;
        closeToast();

        return false;
      }

      console.log(postobj);

      this.axios.post(this.actions.saveidprofile, postobj).then((response) => {
        //保存
        console.log(response);
        let retdata = response.data;

        if (retdata.retcode == "OK") {
          this.applystep = retdata.ApplStep;
          this.Amount = retdata.Deposit;
          this.Deposit = retdata.Deposit;
          this.isloadingdata = false;
          closeToast();
          this.onRefresh();
        } else {
          // showFailToast({
          //   message: this.PAGELANG[retdata.retinfo],
          //   onClose: () => {
          //     //this.$router.go(0);
          //     //location.reload();
          //     this.isloadingdata = false;
          //   },
          // });

          showNotify({
            message: this.PAGELANG[retdata.retinfo],
            type: "warning",
            onClose: () => {
              //this.$router.go(0);
              //location.reload();
              this.isloadingdata = false;
              closeToast();
            },
          });
        }
      });
    },
    onLogout() {
      //退出登录
      setCookie("agentloginid", "");
      setCookie("agentlogintoken", "");
      this.$router.push("/login");
    },
  },
  created() {
    this.PAGELANG = this.LANG.pages.appflow;
    this.SYSLANG = this.LANG.system;
  },
};
</script>